<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        รายงานยอดขาย
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!--      <v-btn @click="$router.push({path:'/maintenance/create'})" color="primary">-->
      <!--        <v-icon left>-->
      <!--          mdi-plus-->
      <!--        </v-icon>-->
      <!--        เพิ่มประวัติการซ่อมบำรุง-->
      <!--      </v-btn>-->
      <v-btn icon @click="showFilter = !showFilter">
        <v-icon>
          mdi-filter
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div class="mr-4 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" class="pl-4 pb-4">
          <v-card
              style="height: 100%;overflow: auto"
              class="pa-2 rounded-lg elevation-2"
              outlined
              tile
          >
            <v-data-table
                :headers="headers"
                :items="items"
                :header-props="{ sortIcon: null }"
            >
              <template slot="body.append">
                <tr style="background-color: #eaf8ff">
                  <td colspan="3">
                    ยอดขาย
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-center">{{ sum('quantity') }}</td>
                  <td class="text-center text-decoration-underline">{{ $currency(sum('net')).format() }}</td>
                  <td class="text-center">{{ sum('discount') }}</td>
                  <td class="text-center text-decoration-underline">
                    {{ $currency(sum('total_price')).format() }}
                  </td>
                  <td></td>
                </tr>
                <tr style="background-color: #fff7ea">
                  <td colspan="3">
                    vat รวม
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center text-decoration-underline">
                    {{ $currency(total_vat).format() }}
                  </td>
                  <td></td>
                </tr>
                <tr style="background-color: #eafff0">
                  <td colspan="3">
                    ยอดขายรวม vat
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center text-decoration-underline">
                    {{ $currency(sum('total_price') + total_vat).format() }}
                  </td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </pane>
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <FilterCard @search="getData"
                      :search-option="findHeaders"
                      date
                      price-min-max
                      seller
                      category-name
                      csv
                      :json-data="csvData"
                      :labels="csvLabels"
                      file-name="รายงานยอดขาย"
                      delivery-type
          />
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import FilterCard from "@/components/FilterCard";

export default {
  components: {Splitpanes, Pane, FilterCard},
  name: "received",
  data: () => ({
    showFilter: true,
    search: '',
    headers: [
      {
        text: 'วันที่',
        value: 'order_date',
      },
      {
        text: 'เลขออเดอร์',
        value: 'order_id',
        // align: 'center'
      },
      {
        text: 'ลูกค้า',
        value: 'customer_name'
        // align: 'center'
      },
        {
        text: 'การจัดส่ง',
        value: 'delivery_type',
        align: 'center', width: 154
      },
      {
        text: 'รหัสสินค้า',
        value: 'product_id',
        // align: 'center'
      },
      {
        text: 'สินค้า',
        value: 'product_name',
        // align: 'center'
      },
      {
        text: 'หน่วย',
        value: 'product_unit',
        // align: 'center'
      },
      {
        text: 'หมวดหมู่',
        value: 'product_category',
        // align: 'center'
      },
      {
        text: 'ราคาต่อหน่วย',
        value: 'price_per_unit',
        align: 'right'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        align: 'right'
      },
      {
        text: 'ราคารวม',
        value: 'net',
        align: 'right'
      },
      {
        text: 'ส่วนลด',
        value: 'discount',
        align: 'right'
      },
      {
        text: 'รายรับ',
        value: 'total_price',
        align: 'right'
      },
      {
        text: 'พนักงานขาย',
        value: 'seller',
        align: 'center'
      },

    ],
    items: [],
    queryString: '',
    csvLabels: {
      order_date: {title: 'วันที่'},
      order_id: {title: 'เลขออดเดอร์'},
      customer_name: {title: 'ชื่อลูกค้า'},
      delivery_type: {title: 'การจัดส่ง'},
      product_id: {title: 'รหัสสินค้า'},
      product_name: {title: 'ชื่อสินค้า'},
      product_unit: {title: 'หน่วย'},
      product_category: {title: 'หมวดหมู่'},
      price_per_unit: {title: 'ราค่าต่อหน่วย'},
      quantity: {title: 'จำนวน'},
      net: {title: 'ราคารวม'},
      discount: {title: 'ส่วนลด'},
      total_price: {title: 'ราคาหลังหักส่วนลด'},
      seller: {title: 'พนักงานขาย'},
    },
    findHeaders: [
      {
        text: 'เลขออเดอร์',
        value: 'order_id',
        align: 'center'
      },
      {
        text: 'ลูกค้า',
        value: 'customer_name',
        align: 'center'
      },
      {
        text: 'สินค้า',
        value: 'product_name',
        align: 'center'
      },
    ],
    csvData: [],
    total_vat: 0
  }),
  created() {
    // this.getData()
  },
  methods: {
    getData(query) {
      this.$store.state.isLoading = true
      axios.get('/api/dashboards/order_item_paper/' + query)
          .then(res => {
            this.items = res.data.data
            this.total_vat = res.data.total_vat
            this.csvData = [...this.items]
            this.csvData.push({
              order_date: 'รวม',
              order_id: '',
              customer_name: '',
              delivery_type: '',
              product_id: '',
              product_name: '',
              product_unit: '',
              product_category: '',
              price_per_unit: '',
              quantity: this.sum('quantity'),
              net: this.sum('net'),
              discount: this.sum('discount'),
              total_price: this.sum('total_price'),
              seller: '',
            })
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          }).finally(() =>  this.$store.state.isLoading = false)
    },
    sum(field) {
      return this.items.reduce((accum, item) => accum + item[field], 0)
    }
  }
}
</script>

<style scoped>

</style>
